.wrapper {
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
}

.mainPart {
    display: grid;
}

.textAbout {
    grid-column: 1;
}

.textMain {
    background: -webkit-linear-gradient(0.65turn, #F464FE, #FF0083);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
}

.textSubMain {
    background: -webkit-linear-gradient(0.15turn, #FE2F57, #FF6A28);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-left: 50px;
    padding-top: 50px;
    font-size: 40px;
}

.spanP {
    display: flex;
    font-size: 30px;
}

.textSubLi {
    background: -webkit-linear-gradient(0.15turn, #FE2F57, #FF6A28);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-left: 60px;
    margin: 0;
}

.subLiStiker {
    width: 35px;
    height: 35px;
    padding-left: 10px;
    margin: 0;
}

.wrapperMe {
    display: flex;
    grid-column: 2;
    justify-content: center;
    align-items: center;
}

.me {
    border-radius: 30px;
    width: 394px;
    height: 525px;
}

.stikers {
    display: grid;
    margin: 0;
    height: 150px;
}

.stiker1 {
    font-size: 150px;
    margin-top: 70px;
    margin-left: 25px;
    transform: rotate(15deg);
}

.stiker1Img {
}

.stiker2 {
    font-size: 80px;
    transform: rotate(-15deg);
}

.stiker2Img {
    height: 80px;
    width: 80px;
}

@media screen and (max-width: 450px) {
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .mainPart {
        display: flex;
        flex-wrap: wrap;
    }

    .textSubMain {
        padding-left: 10px;
        padding-top: 10px;
        font-size: 35px;
    }
    .textSubLi {
        padding-left: 15px;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    .wrapperMe {
        width: 100%;
    }

    .me {
        width: 300px;
        height: 400px;
        margin-top: 25px;
    }

    .stikers {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .stiker1 {
        font-size: 20px;
    }

    .stiker1Img {
        height: 80px;
        width: 80px;
    }
}