.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}


/*!* HTML: <div class="loader"></div> *!*/
/*.loader {*/
/*  width: 40px;*/
/*  aspect-ratio: 1;*/
/*  display: grid;*/
/*}*/
/*.loader::before,*/
/*.loader::after {*/
/*  content: "";*/
/*  grid-area: 1/1;*/
/*  background: orange;*/
/*  clip-path: polygon(0 0,101% 0, 0 100%);*/
/*  animation: l13 2s infinite;*/
/*}*/
/*.loader::after {*/
/*  --s:-1,-1;*/
/*}*/
/*@keyframes l13 {*/
/*    0%,*/
/*    10%  {transform:scale(var(--s,1)) translate(0,0)        rotate(0deg)}*/
/*    33%  {transform:scale(var(--s,1)) translate(20px,-20px) rotate(0deg)}*/
/*    66%  {transform:scale(var(--s,1)) translate(20px,-20px) rotate(180deg)}*/
/*    90%,*/
/*    100% {transform:scale(var(--s,1)) translate(0px,0px)    rotate(180deg)}*/
/*}*/

.loader {
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;
}

.loader:before,
.loader:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 20px;
    width: 20px;
}
.loader:before {
    animation: ball1 1s infinite;
    background-color: #fff;
    box-shadow: 30px 0 0 #64E408;
    margin-bottom: 10px;
}
.loader:after {
    animation: ball2 1s infinite;
    background-color: #64E408;
    box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
    0% { transform: rotate(0deg) scale(0.8) }
    50% { transform: rotate(360deg) scale(1.2) }
    100% { transform: rotate(720deg) scale(0.8) }
}

@keyframes ball1 {
    0% {
      box-shadow: 30px 0 0 #64E408;
    }
    50% {
      box-shadow: 0 0 0 #64E408;
      margin-bottom: 0;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #64E408;
      margin-bottom: 10px;
    }
}

@keyframes ball2 {
    0% {
      box-shadow: 30px 0 0 #fff;
    }
    50% {
      box-shadow: 0 0 0 #fff;
      margin-top: -20px;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #fff;
      margin-top: 0;
    }
}
