@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
}

#root {
    width: 100%;
    max-width: 1620px;
}