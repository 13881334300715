.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapperTitle {
}

.titleText {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 24px;
    font-weight: bold;
}

.wrapperGradesScale {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

@media screen and (max-width: 700px) {
  .wrapperGradesScale {
      width: 350px;
  }
}