.wrapperCard {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 350px;
    width: 350px;
    margin-bottom: 15px;
}

.imgProject {
    width: 100%;
    height: 100%;
    object-fit: cover;
}