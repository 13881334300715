.container {
    font-family: Montserrat, sans-serif;
}

.aboutMe {
    width: 100%;
}

.carusel {
}

.catalogProject {
    margin-bottom: 100px;
}

.portfolioBlock {
    margin-bottom: 20px;
}

.contactBlock {
    margin-bottom: 50px;
}

@media screen and (max-width: 450px) {
    .catalogProject {
        margin-bottom: 20px;
    }
}