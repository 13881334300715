.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 36em;
}

.containerAuthBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapperInfoTable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-top: 100px;
}

.containerInfoTable {
    border: 2px solid black;
    border-radius: 20px;
    padding: 20px;
}

.infoTable {
    margin: 0;
    width: 100%;
    height: 100%;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
}

.babyChiken {
    width: 64px;
    height: 64px;
    position: relative;
    left: -35%;
    bottom: -2px;
}

@media screen and (max-width: 450px) {

    .container {
        height: 39em;
    }

    .containerAuthBtn {
        width: 90%;
    }

    .wrapperInfoTable {
        margin-top: 70px;
    }

}