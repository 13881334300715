.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.containerData {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: max(5%, 10px);
}

.containerImgData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    margin-bottom: 20px;
}

.containerMainData{
    display: flex;
    flex-direction: column;
    width: max(300px, 40%);
    margin-bottom: 40px;
}

.containerImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 400px;
    overflow: hidden;
    border-radius: 15px;
}

.imgProject {
    width: 100%;
}

.containerProjectData {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.containerTitle {
    width: 100%;
}

.title {
    font-weight: bold;
    font-size: 40px;
    margin: 0;
}

.containerDescription {
    margin-top: 10px;
}

.description {
    margin: 0;
    font-size: 25px;
    font-weight: 400;
}

.containerButtonAction {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.linkAction {
    width: 70%;
}

.buttonAction {
    font-family: Montserrat, serif ;
    font-size: 25px;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 10px;
    background: linear-gradient(0.15turn, #1FA5FF, #1053FF);
    width: 100%;
    height: 50px;
    transition: all 100ms ease-in-out;
}

.buttonAction:hover {
    transform: scale(1.1);
}

.containerSputnikEmoji {
    display: flex;
    justify-content: end;
    margin-top: 50px;
}

.containerGrade {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerCommentsList {
    display: block;
    width: 100%;
    margin-bottom: 40px;
}

@media screen and (max-width: 700px) {
    .containerSputnikEmoji {
        height: 0;
        width: 0;
        overflow: hidden;
    }

    .containerMainData {
        margin-bottom: 0;
        margin-top: 40px;
    }
}