.container {
    width: 100%;
    height: 100%;
}

.btnSend {
    background: none;
    border-color: #5459FF;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.btnSend:hover {
    cursor: pointer;
}

.imgUISend {
    width: 100%;
    padding-right: 2px;
}