.wrapper {
    display: flex;
    grid-template-columns: auto;
    height: 100px;
}

.wrapperLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.logo {
    width: 64px;
    height: 64px;
}

.titleHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 60%;

}

.mainText {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.auth {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
}

.containerAvatar {
    height: 75px;
    width: 75px;
    border-radius: 100%;
}

@media screen and (max-width: 450px) {
    .mainText {
        font-size: 24px;
    }

    .wrapperLogo {
        width: auto;
        margin-left: 10px;
    }

    .auth {
        width: auto;
    }
}