.container {

}

.wrapperTitleBlock {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.TitleBlock {
    margin: 0;
    background: -webkit-linear-gradient(0.65turn, #8E2DE2, #4A00E0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
}

.wrapperContainer {
    margin-left: 50px;
    margin-right: 50px;
    height: 100%;
}

.containerProject{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    grid-auto-rows: minmax(720px, 1fr);
    gap: 10px;
}

.wrapperLoader{
    width: 100%;
    height: 720px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapperError {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapperAddBtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.moreAddBtn {
    width: 350px;
    height: 60px;
    background: linear-gradient(0.15turn, #04CE9B, #64E408);
    border-radius: 15px;
    border: none;
    cursor: pointer;
    transition: transform 100ms ease-in-out;
}

.moreAddBtn:hover {
    transform: scale(1.1);
}

.moreAddText {
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

@media screen and (max-width: 450px) {
    .containerProject{
        grid-auto-rows: minmax(650px, 1fr);
        gap: 0;
    }

    .wrapperContainer {
        margin-left: 0;
        margin-right: 0;
    }

    .wrapperAddBtn {
        margin-bottom: 75px;
    }
}