.container {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 650px;
    justify-content: center;
    margin-bottom: 20px;
}

.infoCard {
    display: block;
    width: 100%;
    max-height: 600px;
}

.wrapperInfoCard {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 25px;
    max-width: 350px;
    background-color: transparent;
    border: none;
}

.wrapperInfoCard:hover {
    border-radius: 15px;
    border: 3px solid rgba(120, 47, 239, 1);
    padding: 22px;
}

.wrapperInfoText{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    margin-bottom: 15px;
    font-family: Montserrat, serif;
}

.infoTextName {
    font-size: 40px;
    font-weight: bold;
    margin: 0;
}

.infoText{
    font-size: 26px;
    font-weight: 300;
    margin: 0;
}

.moreInfoBtn {
    width: 100%;
    height: 60px;
    background: linear-gradient(0.15turn, #7F00FF, #E100FF);
    border-radius: 15px;
    border: none;
    cursor: pointer;
    transition: transform 100ms ease-in-out;
}

.moreInfoBtn:hover {
    transform: scale(1);
}

.moreInfoText {
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

@media screen and (max-width: 400px) {
    .wrapperInfoCard {
        transform: scale(0.95);
    }
}