.container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.wrapperMainText {

}

.textMain {
    font-size: 45px;
}

.containerInput {
    display: grid;
    margin-left: 50px;
    margin-right: 50px;
    gap: 25px;
    grid-template-columns: repeat(4, auto);
}

.wrapperInputBox1 {
    display: block;
    grid-column: 1/4;
}

.wrapperInputBox2 {
    display: block;
    grid-column: 4/7;
}

.wrapperInputBox3 {
    display: block;
    grid-column: 7/10;
}

@media screen and (max-width: 700px) {
    .containerInput {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}