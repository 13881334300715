.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  overflow-y: auto;
  transition: opacity 0.3s;
  z-index: 3;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

.modalContent {
  position: relative;
  margin: 20px;
  width: 100%;
  max-width: 600px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 20px 20px 20px 20px;
}

.modalCloseBtn {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: transparent;
  border: none;
}

.title {
  margin: 0 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.msg {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}