.wrapper {
    display: grid;
    grid-template-columns: auto;
    height: 100px;
}

.logo {
    display: flex;
    grid-column: 1/5;
    justify-content: center;
    align-items: center;
}

.arr {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-left: 10px;
}

.wrapperStiker {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 7/32;
    font-size: 72px;
    height: 100px;
}

.stiker {
    width: 64px;
    height: 64px;
    transform: rotate(15deg);
    margin-bottom: 16px;
}