.container {
    display: block;
    width: 100%;
    margin-bottom: 100px;
}

.wrapperTextMain {
    display: flex;
}

.textMain {
    background: -webkit-linear-gradient(0.1turn, #1B67E8, #6209C3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    margin: 0;
}

.stikerMain {
    width: 60px;
    height: 60px;
    margin-left: 10px;
}

.wrapperPortfolio {
    margin: 30px 50px;
}

.wrapperStikerPlane {
    display: grid;
}

.stikerPlane {
    grid-column: 9/10;
    margin: 0 0 40px 0;
    transform: rotate(-15deg);
    width: 162px;
    height: 162px;
}

.wrapperLoader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapperError {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}