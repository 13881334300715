.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgAvatar {
    width: 85%;
    height: 85%;
    border-radius: 100%;
}