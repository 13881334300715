.container {
    display: flex;
    margin-top: 5px;
}

.subHeader {
    margin: 0;
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.subInfoColor{
    margin: 0 0 0 10px;
    font-size: 20px;
    font-weight: bold;
}
