.A {
    background: #000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.C {
    background: #0095ff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.S {
    background: #782FEF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.R {
    background: #EF2FA2;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.RR {
    background: #E20338;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.RRR {
    background: #FFC11E;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}