.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

.wrapperAvatarPrev {
    width: 48px;
    height: 48px;
}

.wrapperInputBox {
    height: 100%;
    width: 50%;
}

.wrapperSendBtn {
    width: 51px;
    height: 51px;
}