.container {
    display: flex;
    height: 450px;
    width: 800px;
}

.imageCarusel {
    height: 100%;
    width: 100%;
    border-radius: 30px;
}

.gradientOverlay {
    position: absolute; /* Позиционируем наложение абсолютно */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%); /* Градиент от прозрачного к черному */
    pointer-events: none; /* Отключаем взаимодействие с наложением */
    border-radius: 30px;
}

.cardInfo {
    position: absolute;
    top: 65%;
    width: 100%;
}

.titleCard {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description {
    color: white;
    font-size: 20px;
    font-weight: 550;
    display: flex;
    justify-content: center;
    align-items: center;
}
